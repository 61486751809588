<template>
  <div>
    <FormulateForm v-model="busqueda" @submit="buscar" #default="{ isLoading }">
      <div class="flex gap-3">
        <div class="w-4/5">
          <FormulateInput
            type="email"
            name="email_busqueda"
            label="Correo electrónico"
            placeholder="Correo electrónico"
            validation="required|email"
            validation-name="Correo electrónico"
            :disabled="isLoading || disabled"
            :class="{
              'opacity-75': disabled,
            }"
          />
        </div>
        <div class="w-1/5 flex items-start">
          <FormulateInput
            class="mt-6 btn-buscar"
            type="submit"
            :disabled="isLoading || disabled"
            :label="isLoading ? 'Buscando...' : 'Buscar'"
          />
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
export default {
  name: "ClientePedido",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      busqueda: {
        correo_electronico: "",
      },
    };
  },
  methods: {
    async buscar({ email_busqueda }) {
      try {
        const resClientes = await this.$store.dispatch("shippings/find", {
          query: {
            email: email_busqueda,
            name: {
              $ne: null,
            },
            $limit: 1,
            $sort: {
              createdAt: -1,
            },
            $select: ["name", "email", "lastName", "phone"],
          },
        });

        let cliente = {};

        if (resClientes.data.length === 0) {
          cliente = {
            id: "nuevo",
            email: email_busqueda,
          };
        } else {
          cliente = {
            id: resClientes.data[0]._id,
            nombre: resClientes.data[0].name,
            email: resClientes.data[0].email,
            apellido: resClientes.data[0].lastName,
            telefono: resClientes.data[0].phone.e164,
          };
        }
        console.log("Emite cliente", cliente);
        this.$emit("cliente", cliente);

        return true;
      } catch (error) {
        console.log(error);
        this.$emit("cliente", null);
      } finally {
        this.busy = false;
      }
    },
  },
};
</script>

<style lang="scss">
/* Generar estilos para que sea full width, y color de tailwind azul */
.btn-buscar #formulate--ventas-6 {
  @apply bg-blue-500 text-white;
  @apply w-full;
  border: none;
  // Justify center
  // Flex align center
  justify-content: center;
}
.btn-buscar {
  width: 100%;
  // Flex align center
}
</style>