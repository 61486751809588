<template>
  <div class="bg-gray-200 h-full">
    <pedido-confirmado
      v-if="mostrarModal"
      @hide="limpiar()"
      :loader="busyGenerarPedido"
      :order="order"
      :key="modalKey"
    />
    <w-top-bar>Punto de venta</w-top-bar>
    <div class="p-5 flex gap-3 relative">
      <div class="p-5 text-sm bg-white rounded-md shadow w-3/5">
        <div class="border-b pb-3 mb-4 relative">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            Productos
          </h2>
          <p class="text-sm leading-6 text-gray-600">
            Cargar los productos, descuentos y precios.
          </p>

          <div class="absolute right-0 top-0">
            <button
              @click="agregarFila"
              class="border-blue-500 border p-1 rounded text-blue-500 hover:text-blue-700 font-medium text-xs mr-2"
            >
              + Agregar
            </button>
            <button
              @click="tieneDescuento = !tieneDescuento"
              class="border-orange-500 border p-1 rounded text-orange-500 hover:text-orange-700 font-medium text-xs"
            >
              + Descuento
            </button>
          </div>
        </div>

        <table
          v-if="items.length > 0"
          class="min-w-full divide-y divide-gray-200"
        >
          <thead>
            <tr>
              <th
                class="bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Producto
              </th>
              <th
                class="bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Cantidad
              </th>
              <th
                class="bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Precio unitario
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td class="py-4 whitespace-nowrap flex items-center gap-3">
                <svg
                  @click="items.splice(index, 1)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 cursor-pointer hover:text-red-500 zoom-1 z-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
                <select
                  @change="items.push({ producto: '', cantidad: 1, precio: 0 })"
                  v-model="item.producto"
                  class="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm max-w-20"
                >
                  <option value="">Seleccionar producto</option>
                  <!-- Crear opciones para items en products -->
                  <option
                    v-for="product in productsFiltrados"
                    :key="product._id"
                    :value="product"
                  >
                    {{ product.sku }} - {{ product.name }} ({{
                      product.stock || 0
                    }})
                  </option>
                </select>
              </td>
              <td class="py-4 whitespace-nowrap">
                <!-- Select de 1 a 10 -->

                <select
                  v-if="inputCantidadManual.indexOf(index) === -1"
                  @change="checkMasDe10(index)"
                  v-model="item.cantidad"
                  class="py-2 w-24 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="1">1</option>
                  <option v-for="n in 9" :key="n + 1" :value="n + 1">
                    {{ n + 1 }}
                  </option>
                  <!-- Option para mas -->
                  <option value="11">Más de 10</option>
                </select>
                <input
                  v-if="inputCantidadManual.indexOf(index) > -1"
                  class="py-2 w-24 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  type="number"
                  min="1"
                  v-model="item.cantidad"
                />
              </td>
              <td class="py-4 whitespace-nowrap text-right">
                <!-- <w-price :value="obtenerPrecio(item) || 0"></w-price> -->
                <!-- Input paa el valor -->
                <span class="mr-2">$</span>
                <input
                  :value="obtenerPrecio(item)"
                  @input="actualizarPrecio($event.target.value, index, item)"
                  type="number"
                  class="w-auto py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm max-w-10"
                />
                <span class="ml-2">ARS</span>
              </td>
            </tr>
            <tr v-if="tieneDescuento">
              <td class="py-4 whitespace-nowrap flex items-center gap-3">
                <svg
                  v-if="descuentoPorcentaje != '0'"
                  @click="quitarDescuento()"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 cursor-pointer hover:text-red-500 zoom-1 z-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
                <!-- Select entre descuento fijo y porcentaje -->
                <select
                  v-model="descuentoPorcentaje"
                  class="w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm max-w-20"
                >
                  <option value="0">Sin descuento</option>
                  <option value="10">10%</option>
                  <option value="20">25%</option>
                  <option value="30">30%</option>
                  <option value="40">40%</option>
                  <option value="50">50%</option>
                  <option value="100">100%</option>
                  <option value="manual">Monto fijo</option>
                </select>
              </td>
              <td class="py-4 whitespace-nowrap"></td>
              <td class="py-4 whitespace-nowrap text-right">
                <w-money
                  v-if="descuentoPorcentaje === 'manual'"
                  v-model="descuento"
                  label=""
                  name="precio en dolares"
                  currency="ars"
                  placeholder="100 USD"
                  class="max-w-10"
                />
                <w-price
                  class="text-red-500"
                  v-else
                  :value="-descuentoCalculado"
                ></w-price>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="border-t py-4">
                <div class="flex justify-between text-xl">
                  <div class="font-medium">Total</div>
                  <div class="font-medium">
                    <w-price :value="calcularTotal"></w-price>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="text-center">
          <p class="text-sm leading-6 text-gray-600">
            No hay productos cargados.
          </p>
          <!-- Boton -->
          <button
            @click="agregarFila"
            class="border-blue-500 border p-1 rounded text-blue-500 hover:text-blue-700 font-medium text-xs mt-3"
          >
            Agregar producto
          </button>
        </div>
      </div>

      <div class="w-2/5">
        <FormulateForm
          v-model="pedido"
          @submit="generarPedido"
          #default="{ isLoading, hasErrors }"
        >
          <div class="w-full p-5 text-sm bg-white rounded-md shadow">
            <div class="border-b pb-3 mb-4">
              <h2 class="text-base font-semibold leading-7 text-gray-900">
                Venta
              </h2>
              <p class="text-sm leading-6 text-gray-600">
                Cargar los datos de la venta, y el método de pago.
              </p>
            </div>

            <div class="mb-4 w-full">
              <!-- Agregar selector de fechas -->
              <FormulateInput
                  type="datetime-local"
                  name="createdAtManual"
                  label="Fecha y hora de la venta"
                  placeholder="Fecha y hora de la venta"
                  help="Si no se completa, se guardará la fecha actual."
                  validation="required"
                />
            </div>

            <div class="mb-4 w-full">
              <FormulateInput
                type="select"
                name="tipoVenta"
                class="w-100"
                :options="tipoDeVentas"
                label="¿Qué tipo de venta es?"
                placeholder="Seleccionar tipo de venta"
                validation="required"
                validation-name="tipo de venta"
              />
            </div>
            <div v-if="pedido.tipoVenta === 'evento'" class="mb-4 w-full">
              <FormulateInput
                type="text"
                name="nombreDeEvento"
                class="w-100"
                label="¿Cuál es el nombre y fecha del evento?"
                placeholder="Se guardará como referencia"
                validation="required"
                validation-name="evento"
              />
            </div>
            <div class="flex gap-3">
              <div class="mb-4 w-1/2">
                <FormulateInput
                  type="select"
                  name="vendedor"
                  class="w-100"
                  :options="vendedores"
                  label="¿Quién es el encargado de la venta?"
                  placeholder="Seleccionar vendedor"
                  help="Se puede cambiar en cualquier momento."
                  validation="required"
                  validation-name="vendedor"
                />
                <!-- Sino, mostrar un input -->
                <!-- <FormulateInput
                  v-else
                  type="text"
                  name="vendedor"
                  label="¿Quién es el encargado de la venta?"
                  placeholder="Nombre del vendedor"
                  validation="required"
                  validation-name="vendedor"
                >
                  <template #help>
                    <button
                      @click="vendedorManual = false"
                      class="text-xs text-blue-500 hover:text-blue-700"
                    >
                      Volver al listado
                    </button>
                  </template>
                </FormulateInput> -->
              </div>
              <!-- Imprimir label y nombre fijo -->
              <!-- <div v-else class="mb-4 w-1/2">
                <FormulateInput
                  type="text"
                  name="vendedor"
                  label="¿Quién es el encargado de la venta?"
                  placeholder="Vendedor"
                  :value="venta.vendedor"
                  :disabled="true"
                />
              </div> -->
              <!-- Metodo de pago -->
              <div class="mb-4 w-1/2">
                <FormulateInput
                  type="select"
                  name="metodoPago"
                  :options="metodosDePago"
                  label="¿Cúal es el método de pago?"
                  placeholder="Seleccionar método de pago"
                  validation="required"
                  validation-name="metodo de pago"
                />
              </div>
            </div>
            <div class="mb-4">
              <FormulateInput
                type="textarea"
                name="notas"
                label="Notas"
                placeholder="Notas"
                help="Notas para cargar en la venta."
                validation-name="notas"
              />
            </div>
          </div>
          <div class="w-full p-5 text-sm bg-white rounded-md shadow mt-3">
            <!-- Line separator with text -->
            <div class="border-b pb-3 mb-4">
              <h2 class="text-base font-semibold leading-7 text-gray-900">
                Cliente
              </h2>
              <p class="text-sm leading-6 text-gray-600">
                Cargar los datos de la venta, y el método de pago.
              </p>
            </div>
            <FormulateInput type="group" name="cliente">
              <div v-if="mostrarBusqueda">
                <buscar-cliente
                  name="cliente"
                  @cliente="procesarCliente"
                  :disabled="disableClient"
                ></buscar-cliente>
                <!-- Input -->
                <!-- sinCliente -->
                <FormulateInput
                  type="checkbox"
                  name="disableClient"
                  label="No cargar datos del cliente"
                  class="mt-1"
                  v-model="disableClient"
                />
              </div>
              <div v-else-if="!disableClient">
                <div class="w-full mb-3">
                  <FormulateInput
                    type="email"
                    name="email"
                    label="Correo electrónico"
                    placeholder="Correo electrónico"
                    validation="required|email"
                    validation-name="correo electrónico"
                  />
                </div>
                <div class="flex gap-3 mb-3">
                  <div class="w-1/2">
                    <FormulateInput
                      type="text"
                      name="nombre"
                      label="Nombre"
                      placeholder="Nombre"
                      validation="required"
                    />
                  </div>
                  <div class="w-1/2">
                    <FormulateInput
                      type="text"
                      name="apellido"
                      label="Apellido"
                      placeholder="Apellido"
                      validation="required"
                    />
                  </div>
                </div>
                <div class="w-full mb-3">
                  <FormulateInput
                    type="tel"
                    name="telefono"
                    label="Teléfono"
                    placeholder="Teléfono"
                    validation="required"
                    validation-name="teléfono"
                  />
                </div>
              </div>
              <div v-if="disableClient" class="mt-3">
                <!-- Texto que explica y link a cargar datos -->
                <p class="text-sm leading-6 text-gray-600">
                  Estás creando un pedido sin cliente. Para voler y
                  <button
                    @click="
                      () => {
                        sinCliente = false;
                        pedido.cliente = [{}];
                      }
                    "
                    class="text-sm text-blue-500 hover:text-blue-700"
                  >
                    cargar los datos, hace click acá
                  </button>
                  .
                </p>
              </div>
            </FormulateInput>
          </div>
          <div v-if="listoGenerar" class="text-right">
            <FormulateInput
              class="mt-6 w-100 btn-100"
              type="submit"
              :disabled="isLoading || hasErrors"
              :label="isLoading ? 'Generando...' : 'Generar pedido'"
            />
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>
  
<script>
import { makeFindMixin } from "feathers-vuex";
import BuscarCliente from "@/components/nuevo/BuscarCliente.vue";
import PedidoConfirmado from "@/components/nuevo/PedidoConfirmado.vue";
export default {
  components: {
    BuscarCliente,
    PedidoConfirmado,
  },
  data() {
    return {
      vendedorManual: false,
      disableClient: false,
      modalKey: 1,
      order: null,
      mostrarModal: false,
      pedido: {
        metodoPago: "mercadopago",
        tipoVenta: "venta-local",
      },
      tipoDeVentas: [
        {
          label: "Venta en Local Físico",
          value: "venta-local",
        },
        {
          label: "Libreria: Venta",
          value: "libreria-venta",
        },
        {
          label: "Libreria: Consignación",
          value: "libreria-consigancion",
        },
        {
          label: "Evento",
          value: "evento",
        },
      ],
      metodosDePago: [
        {
          label: "Efectivo",
          value: "efectivo",
        },
        {
          label: "Mercado Pago",
          value: "mercadopago",
        },
        {
          label: "Tarjeta",
          value: "tarjeta",
        },
        {
          label: "Cortesia",
          value: "cortesia",
        },
        {
          label: "Otro",
          value: "otro",
        },
      ],
      vendedores: [
        // Fran, Nico, Rocio
        {
          label: "Carito",
          value: "caro",
        },
        {
          label: "Fran",
          value: "fran",
        },
        {
          label: "Pame",
          value: "pame",
        },
        {
          label: "Sofía",
          value: "sofia",
        },
        {
          label: "Walter",
          value: "walter",
        },
        {
          label: "Otro",
          value: "otro",
        },
      ],
      busyBuscarCliente: false,
      busyGenerarPedido: true,
      clienteGuardado: null,
      nuevoCliente: false,
      clienteBuscado: false,
      descuentoPorcentaje: 0,
      items: [{ producto: "", cantidad: 1, precio: 0 }],
      descuento: 0,
      tieneDescuento: false,
      // Cliente
      cliente: {
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
      },
      venta: {
        metodoPago: "mercadopago",
        vendedor: "",
        notas: "",
      },
      sinCliente: false,
      inputCantidadManual: [],
    };
  },
  created() {
    // Obtener vendedor de localstorage
    const vendedor = localStorage.getItem("vendedor");
    if (vendedor) this.venta.vendedor = vendedor;
    this.aceptarPistola();
  },
  computed: {
    isStaffAdmin() {
      // Obtener el nombre del usuario
      const user = this.$store.state.auth.user;
      if (user.email === "admin@hernancasciari.com") return false;
      return true;
    },
    debug() {
      // si hay un parametro debug en la url, entonces debug = true
      return this.$route.query.debug;
    },
    mostrarBusqueda() {
      if (
        this.pedido?.cliente &&
        this.pedido.cliente[0] &&
        this.pedido.cliente[0].id
      )
        return false;
      return true;
    },
    listoGenerar() {
      if (this.disableClient) return true;
      if (!this.pedido.cliente) return false;
      if (!this.pedido.cliente[0]) return false;
      return true;
    },
    faltanDatos() {
      // Devolver si faltan datos, el nombre de los campos que faltan

      const faltanDatos = [];
      // Venta
      if (!this.venta.metodoPago) faltanDatos.push("metodoPago");
      if (!this.venta.vendedor) faltanDatos.push("vendedor");
      // Si no tiene productos
      if (this.items.length === 0) faltanDatos.push("productos");
      // Filtrar los items con producto
      const itemsConProducto = this.items.filter((item) => item.producto);
      // Si no tiene productos
      if (itemsConProducto.length === 0) faltanDatos.push("productos");

      if (!this.cliente.email) return faltanDatos;

      if (!this.cliente.email) faltanDatos.push("email");
      if (!this.cliente.nombre) faltanDatos.push("nombre");
      if (!this.cliente.apellido) faltanDatos.push("apellido");
      if (!this.cliente.telefono) faltanDatos.push("telefono");
      if (!this.venta.metodoPago) faltanDatos.push("metodoPago");
      if (!this.venta.vendedor) faltanDatos.push("vendedor");

      // Tambien si tienen menos de 3 chars
      if (this.cliente.email.length < 3) faltanDatos.push("nombre");
      if (this.cliente.nombre.length < 3) faltanDatos.push("nombre");
      if (this.cliente.apellido.length < 3) faltanDatos.push("apellido");
      if (this.cliente.telefono.length < 3) faltanDatos.push("telefono");

      return faltanDatos;
    },
    productsFiltrados() {
      return this.products
      // .filter((product) => {
      //   if (!product.isPublic) return false;
      //   if (product.stock <= 0) return false;
      //   return true;
      // });
    },
    tieneCorreo() {
      // Verificar con regex que el correo sea válido
      return this.cliente.email.includes("@");
    },
    productsParams() {
      return {
        query: {
          $limit: 300,
          $select: [
            "sku",
            "name",
            "_id",
            "type",
            "price",
            "prelaunch",
            "stock",
            "isPublic",
            "upc",
          ],
          prelaunch: { $ne: true },
          $sort: { sku: 1 },
        },
      };
    },
    calcularTotal() {
      console.log("items", this.items);
      let total = this.items.reduce((acc, item) => {
        const precio = this.obtenerPrecio(item);
        // Calcular subtotal
        if (!precio) return acc;
        const subtotal = parseFloat(precio) * (parseFloat(item.cantidad) || 1);
        return acc + subtotal;
      }, 0);

      if (this.descuentoCalculado === "manual")
        return total - parseFloat(this.descuento || 0);
      else return total - this.descuentoCalculado;
    },
    descuentoCalculado() {
      let total = this.items.reduce((acc, item) => {
        const precio = this.obtenerPrecio(item);
        // Calcular subtotal
        if (!precio) return acc;
        const subtotal = parseFloat(precio) * (parseFloat(item.cantidad) || 1);
        return acc + subtotal;
      }, 0);
      const descuento = parseFloat(this.descuentoPorcentaje || 0);
      return total * (descuento / 100);
    },
  },
  // watch: {
  //   // deep watch items, and calcular precio
  //   items: {
  //     handler: function (val) {
  //       console.log("items", val);
  //       this.precio = val.reduce((acc, item) => {
  //         if (!item.producto) return acc;
  //         console.log("precio", item.producto.price.ars);
  //         return acc + parseFloat(item.producto.price.ars);
  //       }, 0);
  //     },
  //     deep: true,
  //   },
  // },
  mixins: [makeFindMixin({ service: "products" })],
  watch: {
    descuentoPorcentaje(val) {
      if (val === "manual") this.descuento = 0;
    },
  },
  methods: {
    limpiar() {
      this.mostrarModal = false;
      this.modalKey++;
      // Clear products, note and client.
      this.items = [{ producto: "", cantidad: 1, precio: 0 }];
      this.pedido = {
        metodoPago: "mercadopago",
        tipoVenta: "venta-local",
      };
      this.cliente = {
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
      };
    },
    checkMasDe10(index) {
      // Si es mas de 10, abrir un input para que pongan la cantidad
      const cantidad = this.items[index].cantidad;
      if (cantidad > 10) {
        this.inputCantidadManual.push(index);
      } else {
        this.inputCantidadManual = this.inputCantidadManual.filter(
          (i) => i !== index
        );
      }
    },
    setError(error) {
      this.$toast.error(error);
    },
    aceptarPistola() {
      if (!window.pistola) {
        window.pistola = true;

        let isbn = "";
        const self = this;
        document.addEventListener("keydown", function (event) {
          // si this.debug = true, entonces cuando apretan º reemplaza isbn por 978-84-15525-32-5
          console.log("event", event.keyCode);
          if (self.debug) {
            if (event.keyCode === 192) {
              console.log("debug");
              isbn = "978-84-15525-29-5";
            } else if (event.keyCode === 187) {
              isbn = "***DEMO***";
            } else {
              return;
            }
          }

          // Check if the pressed key is not the enter key
          if (event.keyCode !== 13 && !self.debug) {
            // Only characters A-z and 0-9 are allowed and :,//,.,- are allowed
            if (
              !(
                (event.keyCode >= 65 && event.keyCode <= 90) ||
                (event.keyCode >= 48 && event.keyCode <= 57) ||
                event.keyCode === 186 ||
                event.keyCode === 191 ||
                event.keyCode === 190 ||
                event.keyCode === 189
              )
            ) {
              return;
            }

            // Append the pressed key to the URL
            isbn += event.key;
          } else {
            if (isbn.includes("http")) return;

            // Verificar si es un isbn valido, solo puede tener numeros y guiones, por ej: 978-84-15525-32-5
            const isbnValido = isbn.match(/^[0-9-]+$/);

            if (isbnValido) {
              console.log("isbn", isbn);
              // Buscar producto por isbn
              // const producto = self.productsFiltrados.find(
              //   (product) => product.upc === isbn
              // );

              // if (!producto) {
              //   self.$toast.error(
              //     "No se encontró el producto con el ISBN: " + isbn
              //   );
              //   return;
              // } else {
              //   // Sumar a items, o incrementar la cantidad
              //   const item = self.items.find(
              //     (item) => item.producto._id === producto._id
              //   );
              //   if (item) {
              //     item.cantidad++;
              //     self.$toast.success(
              //       "Se incrementó la cantidad del producto: " + producto.name
              //     );
              //   } else {
              //     // Agregarlo ultimo
              //     self.items.push({ producto, cantidad: 1 });
              //     self.$toast.success(
              //       "Se agregó el producto: " + producto.name
              //     );
              //   }
              // }
            } else {
              self.$toast.error("El ISBN es invalido: " + isbn);
              return;
            }
          }
        });
      }
    },
    agregarYSeleccionarVendedor(vendedor) {
      if (vendedor) {
        this.vendedores.push({ label: vendedor, value: vendedor });
        this.venta.vendedor = vendedor;
      } else {
        this.venta.vendedor = this.vendedores[0].value;
      }
    },
    procesarCliente(cliente) {
      this.pedido = {
        ...this.pedido,
        cliente: [cliente],
      };
    },
    limpiarCliente() {
      this.cliente = {
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
      };
      this.clienteGuardado = null;
      this.clienteBuscado = false;
    },
    quitarDescuento() {
      this.tieneDescuento = false;
      this.descuentoPorcentaje = 0;
    },
    guardarVendedor() {
      localStorage.setItem("vendedor", this.venta.vendedor);
    },
    agregarFila() {
      this.items.push({ producto: "", cantidad: 1, precio: 0 });
    },
    obtenerPrecio(item) {
      if (!item.producto) return 0;
      return item.producto.price.ars;
    },
    actualizarPrecio(precio, index, item) {
      this.items[index].producto.price.ars = precio;
      item.producto.price.ars = precio;
    },

    async obtenerIdCliente() {
      let cliente = this.pedido.cliente[0];

      if (this.disableClient) {
        cliente.nombre = "Compra en tienda";
        cliente.apellido = "";
        cliente.email = "";
        cliente.telefono = "";
        cliente.id = "nuevo";
      }

      if (cliente.id !== "nuevo") {
        return cliente.id;
      }

      cliente = await this.$store.dispatch("shippings/create", {
        email: cliente.email || "sincorreo@orsai.org",
        name: cliente.nombre || "Sin nombre",
        lastName: cliente.apellido || "",
        phone: cliente.telefono || "",
        // notes: this.cliente.notas,
      });

      return cliente._id;
    },

    async buscarCliente() {
      this.busyBuscarCliente = true;

      const resClientes = await this.$store.dispatch("shippings/find", {
        query: {
          email: this.cliente.email,
        },
      });

      if (resClientes.total === 0) {
        this.nuevoCliente = true;
        this.busyBuscarCliente = false;
        this.clienteBuscado = true;
        this.clienteGuardado = this.cliente;
        return;
      }

      let cliente = resClientes.data[0];

      this.clienteGuardado = cliente;
      // Reemplazar valores de cliente con los del cliente guardado
      this.cliente.nombre = cliente.name;
      this.cliente.apellido = cliente.lastName;
      this.cliente.telefono = cliente.phone.e164;
      this.busyBuscarCliente = false;
      this.clienteBuscado = true;
    },

    async crearCliente() {
      const cliente = await this.$store.dispatch("shippings/create", {
        email: this.pedido.cliente.email,
        name: this.pedido.cliente.nombre,
        lastName: this.pedido.cliente.apellido,
        phone: this.pedido.cliente.telefono,
        // notes: this.cliente.notas,
      });

      this.clienteGuardado = cliente;
    },
    async generarPedido() {
      const idCliente = await this.obtenerIdCliente();

      if (!idCliente) return this.setError("Faltan los datos del cliente");

      const productosValidos = this.items.filter(
        (item) => item.producto && item.producto._id
      );

      const cantProductosValidos = productosValidos.length;

      if (cantProductosValidos === 0)
        return this.setError("Faltan seleccionar los productos");

      this.busyGenerarPedido = true;
      this.mostrarModal = true;
      const cart = productosValidos.map((item) => {
        return {
          _id: item.producto._id,
          sku: item.producto.sku,
          quantity: item.cantidad,
          qty: item.cantidad,
          name: item.producto.name,
          price: item.producto.price,
        };
      });
      const total = this.calcularTotal;
      const nuevaOrden = {
        // Compra en tienda
        shippingId: idCliente,
        shippingMethodId: "646ce9a652fe020011ec419f",
        cart,
        total: total + this.descuentoCalculado,
        totalAmount: total + this.descuentoCalculado,
        discountAmount: this.descuentoCalculado,
        paidAmount: total,
        payment: "paid",
        status: "paid",
        notifications: {
          confirmation: true,
        },
        discount: this.descuentoPorcentaje,
        toRemove: true,
        vendedor: this.venta.vendedor,
        metodoPago: this.venta.metodoPago,
        tipoVenta: this.pedido.tipoVenta,
        createdAtManual: this.pedido.createdAtManual,
      };
      console.log("pre order", nuevaOrden);
      const order = await this.$store.dispatch("orders/create", nuevaOrden);
      console.log("order", order);
      this.order = order;
      this.modalKey++;
      this.busyGenerarPedido = false;
    },
  },
};
</script>
  
<style lang="scss">
.max-w-20 {
  max-width: 20rem;
}
.max-w-10 {
  max-width: 7rem;
}

.btn-100 button {
  width: 100% !important;
}
.btn-100 button {
  justify-content: center;
}
.error-notification {
  min-width: 20rem;
  margin-top: 2rem;
  margin-right: 2rem;
  z-index: 100;
}
</style>
  